window.angular.module('MyHippoProducer').directive('inFlowNavigation', function ($log) {
    'ngInject';
    return {
        restrict: 'E',
        scope: {},
        replace: true,
        template: `
            <div>
                <incentives-banner ng-if="showIncentiveBanner" close="closeIncentiveBanner()"
                    title="confirmationBannerTitle" text="confirmationBannerText" link="confirmationBannerLink">
                </incentives-banner>
                <div class="lead-navigation" ng-class="{'minimize-navigation': isMinimized || tempMinimize, 'hidden': isHidden}" ng-hide="isDisabled">
                    <div class="nav-toggle" ng-click="minimizeNavigation()">
                        <span>NAV</span>
                        <div class="nav-arrow left"></div>
                    </div>
                    <div class="page-nav">
                        <div class="nav-arrow up" ng-click="scroll(-100)" ng-show="showScrollTop"></div>
                        <div class="nav-item" ng-class="{'active': activePage === $index }" ng-show="shouldShowNavPage(page, $index)" ng-repeat="page in navList track by $index" ng-click="navigateToPage($index)">
                            <div class="nav-image">
                                <img ng-src="{{page.quickNavIcon}}" />
                                <div class="selection-shadow"></div>
                            </div>
                            <p>{{page.quickNavTitle}}</p>
                        </div>
                        <div class="nav-arrow down" ng-click="scroll(100)" ng-show="showScrollBottom"></div>
                    </div>
                </div>
            </div>
        `,
        controller: function ($scope, $timeout, $state, IncentivesService, HeapService, APIService, UserService, ReactRedirectService) {
            'ngInject';
            const {_} = window;

            // Defaults
            $scope.isMinimized = true;
            $scope.isHidden = true;
            $scope.isDisabled = false;
            $scope.furthestPage = 0;
            $scope.showScrollTop = false;
            $scope.showScrollBottom = false;
            $scope.showIncentiveBanner  = false;
            $scope.confirmationBannerTitle = '';
            $scope.confirmationBannerText = '';
            $scope.confirmationBannerLink = '';
            $scope.incentiveBeforeBind = {};
            $scope.applicationId = '';

            const $leadFrame = () => document.querySelector('#PolicyCreateIFrame');
            const incentiveQuery = '{ Incentive { confirmationBannerTitle confirmationBannerText confirmationBannerLink status incentiveCampaignId } }';

            document.querySelector('.page-nav').addEventListener('scroll', () => {
                $scope.handleScroll();
                $scope.$digest();
            });

            $scope.handleScroll = () => {
                const $pageNav = document.querySelector('.page-nav');
                const scrollHeight = $pageNav.scrollHeight;
                const pageNavHeight = $pageNav.offsetHeight;
                const currentScrollPos = $pageNav.scrollTop;
                $scope.showScrollTop = currentScrollPos > 0;
                $scope.showScrollBottom = currentScrollPos + pageNavHeight < scrollHeight && pageNavHeight < scrollHeight;
            };

            $scope.scrollToActiveTile = () => {
                const item = document.querySelector('.nav-item.active');
                if (item) {
                    item.scrollIntoView();
                    $timeout($scope.handleScroll, 100);
                }
            };

            $scope.scroll = (px) => {
                const $pageNav = document.querySelector('.page-nav');
                const currentScrollPos = $pageNav.scrollTop;
                $pageNav.scrollTop = px + currentScrollPos;
                $scope.handleScroll();
            };

            $scope.$on('$stateChangeStart', function (evt, toState) {
                $scope.isIncentiveEntryPage = toState.name === 'portal.leadDetails' || toState.name === 'portal.policyCreate';
                if (!$scope.isIncentiveEntryPage) {
                    $scope.showIncentiveBanner = false;
                }
                $scope.activePage = null; // Reset active page when state changes
                const validPages = ['portal.leadDetails', 'portal.policyCreate'];
                if (_.includes(validPages, toState.name) && $scope.activePage) {
                    $scope.isHidden = false;
                    $scope.isDisabled = false;
                } else {
                    $scope.furthestPage = 0;
                    $scope.isHidden = true;
                    $scope.isMinimized = true;
                }
            });

            $scope.shouldShowNavPage = (page, pageIndex) => {
                const shouldShowNav =
                pageIndex <= $scope.furthestPage && page.showQuickNav;
                return ReactRedirectService.showNavPage(page.key, shouldShowNav);
            };

            $scope.minimizeNavigation = () => {
                $scope.isMinimized = true;
                postLeadMessage({ eventType: 'closenav' });
            };

            $scope.closeIncentiveBanner = () => {
                $scope.showIncentiveBanner = false;
                updateBannerPosition();
            };

            function updateBannerPosition() {
                if ($scope.showIncentiveBanner) {
                    $leadFrame().classList.add('open-banner', 'iframe-position');
                    $leadFrame().classList.remove('close-banner');
                } else {
                    $leadFrame().classList.add('close-banner', 'iframe-position');
                    $leadFrame().classList.remove('open-banner');
                }
            }

            function handlePageLoad (data) {
                if(data.template === 'final-quote') {
                    const beforeBindQuery = '{ Incentive { status incentiveCampaignId } }';
                    IncentivesService.fetchIncentives(beforeBindQuery).then((response) => {
                        $scope.incentiveBeforeBind = response.data.data.Incentive.find((incentive) => incentive.status === 'ACTIVE');
                    });
                }
                if(data.template === 'confirmation' && $scope.isIncentiveEntryPage && !_.isEmpty($scope.incentiveBeforeBind)) {
                    $timeout(
                        () => {
                            IncentivesService.fetchIncentives(incentiveQuery).then((response) => {
                                const incentiveAfterBind = response.data.data.Incentive.find((incentive) => incentive.status === 'COMPLETED');
                                if(incentiveAfterBind) {
                                    $scope.confirmationBannerTitle = incentiveAfterBind.confirmationBannerTitle;
                                    $scope.confirmationBannerText = incentiveAfterBind.confirmationBannerText;
                                    $scope.confirmationBannerLink = incentiveAfterBind.confirmationBannerLink;
                                    $scope.showIncentiveBanner = $scope.incentiveBeforeBind.incentiveCampaignId === incentiveAfterBind.incentiveCampaignId;
                                    if($scope.showIncentiveBanner) {
                                        HeapService.trackEvent('incentive_success_banner_loads');
                                    }
                                }
                                updateBannerPosition();
                            });
                        },
                        6000
                    );
                }

                $scope.navList = data.pages;
                $scope.activePage = data.page;

                const currentPage = data.pages[data.page];
                $scope.isDisabled = _.includes(['confirmation', 'landing'], currentPage.key);
                $scope.isHidden = data.template === 'call-us' || data.template === 'information-required'; // Hide nav on error pages

                // Set Furthest Page Seen
                if (data.page > $scope.furthestPage && currentPage.key !== 'call-us' && currentPage.key !== 'information-required') {
                    $timeout(() => { $scope.furthestPage = data.page; });
                }
                // Reset on landing page
                if (data.page === 0) {
                    $scope.furthestPage = 0;
                }
                // Automatically minimize navigation for these pages
                $scope.tempMinimize = _.includes(['prelim-quote', 'final-quote'], data.template);
                $timeout($scope.scrollToActiveTile, 100); // Scroll to latest page
            }

            function handlePageSave (data) {
                if ($state.$current.name === 'portal.policyCreate' && data.leadId) {
                    $state.go('portal.leadDetails', { id: data.leadId }, { notify: false });
                }
                // Logic for specific pages and user behaviors
                if (data.template === 'about-yourself') {
                    const claimCheckIdx = _.findIndex($scope.navList, p => p.key === 'claim-check');
                    if (claimCheckIdx > -1 && claimCheckIdx < $scope.furthestPage) $scope.furthestPage = claimCheckIdx;
                } else if (_.includes(['product-select', 'how-property-is-used'], data.template)) {
                    $scope.furthestPage = _.findIndex($scope.navList, p => p.key === data.template);
                } else if (data.template === 'your-home') {
                    const swimmingPoolAnswer = _.get(data, 'leadData.property_data.swimming_pool');
                    const swimmingPoolIdx = _.findIndex($scope.navList, p => p.key === 'swimming-pool');
                    if (swimmingPoolAnswer === true) {
                        $scope.furthestPage = $scope.furthestPage > swimmingPoolIdx ? swimmingPoolIdx : $scope.furthestPage;
                    }
                } else {
                    // If any changes are made before the checkout page we will remove the checkout page from the nav bar
                    // This will check to see if the checkout page exists in the nav bar
                    // if it does, it will set the furthest page they can jump to to final quote page
                    const checkoutPageIndex = _.findIndex($scope.navList, p => p.key === 'checkout');
                    const finalQuotePageIndex = _.findIndex($scope.navList, p => p.key === 'final-quote');
                    const currentPageIndex = _.findIndex($scope.navList, p => p.key === data.template);
                    if (currentPageIndex && $scope.furthestPage >= checkoutPageIndex && currentPageIndex < $scope.furthestPage) {
                        $scope.furthestPage = finalQuotePageIndex;
                    }
                }
            }

            function postLeadMessage (msg) {
                $leadFrame().contentWindow.postMessage(msg, window.LEAD_FLOW_URL); // set by config
            }

            const parseJson = (string) => {
                try {
                    return JSON.parse(string);
                } catch (e) {
                    return {};
                }
            };

            window.addEventListener('message', (event) => {
                $log.info('producer-portal got message', event);
                if (event.origin) {
                    const data = typeof event.data === 'object' ? event.data : parseJson(event.data);
                    if (data.eventType === 'pageload' && $scope.activePage !== data.page) {
                        if (data.leadId) {
                            APIService.getApplicationId(data.leadId).then((data) => {
                                $scope.applicationId = data.application_uuid;
                                $scope.addressId = data.address_uuid;
                            });
                        }
                        handlePageLoad(data);
                    } else if (data.eventType === 'pagesave' || data.eventType === 'pagechange') {
                        handlePageSave(data);
                    } else if (data.eventType === 'opennav') {
                        $scope.isMinimized = false;
                        $scope.tempMinimize = false;
                        $timeout($scope.scrollToActiveTile, 100);
                    } else if (data.eventType === 'modalopen') {
                        $scope.isHidden = true;
                    } else if (data.eventType === 'modalclose') {
                        $scope.isHidden = false;
                    } else if (data.eventType === 'reloadproducerportal') {
                        window.location.reload();
                    }
                    // Required to refresh the view
                    $scope.$digest();
                }
            });

            $scope.navigateToPage = page => {
                const shouldForcePage = $scope.navList[page].key === 'how-property-is-used';
                let isReactOn = false;
                ReactRedirectService.getFlagShipData().then((flagshipData) => {
                    isReactOn = ReactRedirectService.reactPageIsOn(flagshipData, $scope.navList[page].key);
                    if (isReactOn
                        && !ReactRedirectService.pageNotMigration($scope.navList[page].key)
                    ) {
                        ReactRedirectService.navigationToReactPage(
                            $scope.navList[page].key,
                            $scope.applicationId,
                            $scope.addressId,
                            $scope.navList[$scope.activePage].key
                        );
                    } else {
                        postLeadMessage({
                            eventType: 'navigatepage',
                            page,
                            pageKey: $scope.navList[page].key,
                            force: shouldForcePage,
                            currentPage: $scope.activePage,
                            currentPageTemplate: $scope.navList[$scope.activePage].key,
                            furthestPage: $scope.furthestPage
                        });
                    }
                });
            };
        }
    };
});
